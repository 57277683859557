/**
 * @param {string} url
 * @param {boolean} addLogging
 * @returns {Promise<Object>}
 */
export const earnBadge = async (url, addLogging = false) => {
    try {
        const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
        if (!csrfToken) {
            console.error('CSRF token bulunamadı');
            return { success: false, error: 'CSRF token bulunamadı' };
        }
        
        const apiUrl = window.location.origin + url;
        
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest',
                    'Accept': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({})
            });
            
            if (!response.ok) {
                return { 
                    success: false, 
                    error: `API yanıtı başarısız: ${response.status}` 
                };
            }
            
            const responseText = await response.text();            
            let data;
            try {
                if (responseText && responseText.trim() !== '') {
                    data = JSON.parse(responseText);
                    
                    if (data.status === 'ok' && data.data && data.data.success) {                        if (typeof window.showToast === 'function') {
                            window.showToast(data.data.message, 'success');
                        }
                        return data;
                    } else if (data.status === 'ok' && data.data) {
                        if (typeof window.showToast === 'function') {
                            window.showToast(data.data.message, 'info');
                        }
                        return data;
                    } else {
                        console.error('Rozet kazanma hatası:', data.error || 'Bilinmeyen hata');
                        if (typeof window.showToast === 'function' && data.error) {
                            window.showToast(data.error, 'error');
                        }
                    }
                } else {
                    data = { success: false, error: 'API yanıtı boş veya geçersiz.' };
                }
            } catch (parseError) {
                data = { success: false, error: 'API yanıtı işlenemedi' };
            }
            
            return data || { success: false, error: 'Veri alınamadı' };
        } catch (fetchError) {
            console.error('İstek gönderilirken hata oluştu:', fetchError);
            return { success: false, error: 'İstek gönderilirken hata oluştu' };
        }
    } catch (error) {
        console.error('Rozet kazanma işleminde beklenmeyen hata:', error);
        return { success: false, error: error.message || 'Bilinmeyen hata' };
    }
};

/**
 * Oyun tamamlama rozeti kazanma işlevi
 */
export const earnGameCompletionBadge = async () => {
    return earnBadge('/api/teacher-badges/game/completion');
};

/**
 * Şanslı Kim Rozeti kazanma işlevi
 */
export const earnStudentSelectBadge = async () => {
    return earnBadge('/api/teacher-badges/class-management/student-select');
};

/**
 * Ekip Kurucu Rozeti kazanma işlevi
 */
export const earnGroupingBadge = async () => {
    return earnBadge('/api/teacher-badges/class-management/grouping');
};

/**
 * Süre Sihirbazı Rozeti kazanma işlevi
 */
export const earnTimerBadge = async () => {
    return earnBadge('/api/teacher-badges/class-management/timer');
};

/**
 * Bağlılık Rozeti kazanma işlevi
 */
export const earnSchoolSelectionBadge = async () => {
    return earnBadge('/api/teacher-badges/profile/school-selection');
};

/**
 * Onaylı Öğretmen Rozeti kazanma işlevi
 */
export const earnVerifiedTeacherBadge = async () => {
    return earnBadge('/api/teacher-badges/profile/verified-teacher');
};

/**
 * Soru Bankası Rozeti kazanma işlevi
 */
export const earnQuestionSetBadge = async () => {
    return earnBadge('/api/teacher-badges/learning-test/question-set');
};

/**
 * Ödevci Rozeti kazanma işlevi
 */
export const earnHomeworkAssignmentBadge = async () => {
    return earnBadge('/api/teacher-badges/learning-task/assignment');
}; 